<template>
  <v-hover v-slot:default="{ hover }">
    <v-row
      align="center"
      :class="{ 'light-green lighten-5 green--text': hover }"
      class="py-3 py-md-2"
      no-gutters
    >
      <v-col cols="1" class="d-flex justify-center">
        <v-switch
          inset
          v-model="disponibile"
          :loading="loading_change"
          :disabled="loading_change"
        ></v-switch>
      </v-col>
      <v-col
        cols="1"
        v-if="$vuetify.breakpoint.width > 600"
        class="d-flex justify-center"
      >
        <v-avatar size="60" class="mr-3">
          <v-img :src="avatar_img"> </v-img>
        </v-avatar>
      </v-col>
      <v-col cols="5" md="3" class="text-truncate" style="cursor: pointer">
        <span style="font-size: 18px">{{ nome }}</span>
        <br />
        <span>{{ tipo_vaso }}</span>
      </v-col>
      <v-col cols="2" md="1"> Ø {{ item.diametro }} </v-col>
      <v-col cols="2" md="1">
        <span style="font-size: 16px">€ {{ item.prezzo1 }}</span> <br /><span
          class="grey--text"
          style="font-size: 12px"
          >€ {{ prezzo_unitario(item.prezzo1) }}</span
        ></v-col
      >
      <v-col cols="2" md="1">
        <span style="font-size: 16px">€ {{ item.prezzo2 }}</span> <br /><span
          class="grey--text"
          style="font-size: 12px"
          >€ {{ prezzo_unitario(item.prezzo2) }}</span
        ></v-col
      >
      <v-col cols="2" md="1">
        <span style="font-size: 16px">€ {{ item.prezzo3 }}</span
        ><br /><span class="grey--text" style="font-size: 12px"
          >€ {{ prezzo_unitario(item.prezzo3) }}</span
        ></v-col
      >
      <v-col cols="1">
        <v-dialog
          v-model="dialog_galleria"
          max-width="600"
          v-if="item.img_list.length"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon class="mr-4" color="primary" v-on="on">
              <v-icon>mdi-image-multiple</v-icon>
            </v-btn>
          </template>
          <v-card max-width="600">
            <v-card-title class="grey--text font-weight-light">
              <v-icon class="mr-3" color="primary">mdi-camera</v-icon> Galleria
              Immagini
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="image in item.img_list"
                  :key="image.id"
                  class="d-flex child-flex"
                  cols="3"
                >
                  <v-img
                    :src="img_url(image)"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    @click="open_img(image)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-dialog v-model="dialog_enl" width="1200">
                    <v-img
                      :src="image_open"
                      contain
                      @click="dialog_enl = false"
                    ></v-img>
                  </v-dialog>
                </v-col>
              </v-row>
              <!-- <v-dialog v-model="dialog_enl" max-width="1000">
                <v-img
                  :src="image_open"
                  max-height="800"
                  max-width="1200"
                  @click="dialog_enl = false"
                />
              </v-dialog> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="primary" @click="dialog_galleria = false">
                Chiudi
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="1">
        <v-btn icon @click="$refs.modifica_dialog.open()"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <ModificaDialog :prodotto="item" ref="modifica_dialog" />
        <varieta-dialog icon :prodotto="item"/>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
import ModificaDialog from "./modifica_dialog/modifica_dialog.vue";
import VarietaDialog from "./varietà_dialog/varietà_dialog_main.vue"
// import DialogDettaglio from "./dialog_inserimento/listino_dettaglio_prodotto_desktop";
// import FotoGallery from "./listino_fotogallery_desktop";
import prod_element_mixin from "../Mixin/elemento_prodotto_mixin";
export default {
  components: {
    ModificaDialog,
    VarietaDialog
    // DialogDettaglio,
    // FotoGallery,
  },
  mixins: [prod_element_mixin],
  data() {
    return {
      show_dialog: false,
    };
  },
  props: {
    show: Boolean,
  },
};
</script>