<template>
  <v-list>
    <v-list-item  @click="show_inner = !show_inner">
      <v-list-item-content>
        <v-list-item-title class="font-italic font-weight-light green--text">
          {{ sub_categ.nome }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon>
          <v-icon>{{
            show_inner ? "mdi-chevron-down" : "mdi-chevron-up"
          }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <template v-if="show_inner">
      <template v-for="prodotto in sub_categ.prodotti">
        <v-lazy
          :key="prodotto.id"
          :options="{
            threshold: 0.5,
          }"
          min-height="40"
          transition="fade-transition"
        >
          <ProdottoListino
            :item="prodotto"
          />
        </v-lazy>
      </template>
    </template>
  </v-list>
</template>

<script>
import ProdottoListino from "./prodotto_element.vue";
export default {
  components: {
    ProdottoListino,
  },
  props: {
    sub_categ: Object,
    show: Boolean,
  },
  data() {
    return {
      show_inner: true,
    };
  },
  watch: {
    show: function () {
      this.show_inner = this.show;
    },
  },
};
</script>