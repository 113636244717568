<template>
  <v-hover v-slot="{ hover }">
    <v-row
      no-gutters
      align="center"
      :class="hover ? 'green lighten-5' : ''"
      class="py-4"
    >
      <v-col cols="1" class="d-flex justify-center">
        <v-avatar size="20">
          <v-img :src="avatar_colore" />
        </v-avatar>
      </v-col>
      <v-col cols="4">
        {{ varieta.nome }}
      </v-col>
      <v-col cols="3">
        {{ colore.nome }}
      </v-col>
      <v-col cols="3">
        {{ fornitore.nome }}
      </v-col>
      <v-col cols="1">
        <v-dialog v-model="dialog_elimina" width="400" >
          <template v-slot:activator="{ on }">
            <v-btn icon color="red" x-small v-show="hover"  v-on="on">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="red--text"> Elimna Varietà </v-card-title>
            <v-card-text> Sei Sicuro? </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="grey" @click="dialog_elimina = false"> Annulla </v-btn>
              <v-btn outlined color="red" @click="elimina_varietà()" :loading="loading_elimina">
                <v-icon class="ml-2">mdi-delete</v-icon>
                Elimina
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
export default {
  props: {
    varieta: Object,
    prodotto: Object,
  },
  data() {
    return {
        dialog_elimina: false,
        loading_elimina: false
    };
  },
  computed: {
    colore() {
      return this.prodotto.colori.find(
        (col) => col.id == this.varieta.id_colore
      );
    },
    avatar_colore() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto.id +
        "/img_colore/" +
        this.varieta.id_colore
      );
    },
    fornitore() {
      return this.$store.getters.get_fornitore_by_id(this.varieta.id_fornitore);
    },
  },
  methods:{
      elimina_varietà(){
          this.loading_elimina = true
          this.$store.dispatch("elimina_varieta",{id_prodotto: this.prodotto.id, id_varieta: this.varieta.id})
          .finally(()=>{
              this.loading_elimina = false
              this.$emit("eliminata", this.varieta.id)
              this.dialog_elimina = false
          })
      }
  }
};
</script>