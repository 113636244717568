<template>
  <v-dialog v-model="dialog_modifica" fullscreen>
    <v-card>
      <v-img :src="main_img_url" height="100">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card-title>
        <v-list-item class="ml-n4">
          <v-list-item-avatar>
            <v-avatar>
              <v-img :src="avatar_url" />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="primary--text font-weight-light font-italic"
            >
              <span style="font-size: 22px">{{ nome }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ prodotto.nome_sc }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="close()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row align="center" class="mt-2">
          <v-col cols="12">
            <v-icon>mdi-information</v-icon> INFORMAZIONI
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            Unità di Vendita
            <br />
            <span class="headline">
              <v-icon>mdi-google-circles-communities</v-icon> {{ unita }}
            </span>
          </v-col>
          <v-col cols="5">
            Dimensione Vaso:
            <br />
            <span class="headline">
              <v-icon>mdi-diameter-variant</v-icon> {{ prodotto.diametro }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            Cod. Contabilità
            <br />
            <span class="headline">
              <v-icon>mdi-barcode</v-icon> {{ prodotto.cod }}
            </span>
          </v-col>
          <v-col cols="5">
            Unità per CC:
            <br />
            <span class="headline">
              <v-icon>mdi-cart</v-icon> {{ prodotto.qta_cc }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            Listino 1
            <br />
            <span class="headline d-flex align-center">
              <v-icon>mdi-currency-eur</v-icon> {{ prodotto.prezzo1 }}
            </span>
          </v-col>
          <v-col cols="4">
            Listino 2
            <br />
            <span class="headline d-flex align-center">
              <v-icon>mdi-currency-eur</v-icon> {{ prodotto.prezzo2 }}
            </span>
          </v-col>
          <v-col cols="4">
            Listino 3
            <br />
            <span class="headline d-flex align-center">
              <v-icon>mdi-currency-eur</v-icon> {{ prodotto.prezzo3 }}
            </span>
          </v-col>
        </v-row>
        <v-divider class="my-4" />
        <v-row align="center">
          <v-col cols="12"> <v-icon>mdi-palette</v-icon> COLORI </v-col>
        </v-row>
        <Colore
          v-for="colore in prodotto.colori"
          :prodotto="prodotto"
          :colore="colore"
          :key="colore.id"
        />
        <NuovoColore :prodotto="prodotto" />
        <v-divider class="my-4" />
        <v-row align="center">
          <v-col cols="12"> <v-icon>mdi-camera</v-icon> GALLERIA </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <NuovaFoto :prodotto="prodotto.id" />
          </v-col>
          <v-col cols="4" v-for="foto in prodotto.img_list" :key="foto.id">
            <Foto :foto="foto" :prodotto="prodotto.id" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              rows="1"
              label="Info"
              outlined
              hide-details
              counter="60"
              v-model="info"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_info"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_info">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_info">mdi-error</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="close()">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import modifica_dialog_mixin from "../../Mixin/modifica_dialog_mixin";
import NuovaFoto from "../../desktop/modifica_dialog/nuova_foto.vue";
import NuovoColore from "../../desktop/modifica_dialog/nuovo_colore_form.vue";
import Foto from "../../desktop/modifica_dialog/foto_portrait.vue";
import Colore from "../../desktop/modifica_dialog/colore_element.vue";

export default {
  components: {
    NuovaFoto,
    NuovoColore,
    Foto,
    Colore,
  },
  mixins: [modifica_dialog_mixin],
};
</script>