export default {
  props: {
    item: Object,
  },
  data() {
    return {
      show_dialog: false,
      loading_change: false,
      dialog_galleria: false,
      dialog_enl: false,
      image_open: ''
    };
  },
  computed: {
    disponibile: {
      get() {
        return this.item.disponibile;
      },
      set(value) {
        this.loading_change = true;
        this.$store
          .dispatch("cambia_disponibile", {
            id: this.item.id,
            stato: value,
          })
          .then(() => {
            this.loading_change = false;
          });
      },
    },
    nome() {
      if (this.item.nome.includes("Vaso")) {
        return this.item.nome.split("Vaso")[0];
      } else {
        return this.item.nome.split("Basket")[0];
      }
    },
    tipo_vaso() {
      if (this.item.unita == "1") {
        return "Vaso Singolo";
      } else {
        return "Cassa da " + this.item.unita;
      }
    },
    numero_prodotti() {
      return this.item.colori
        .map((e) => e.numero)
        .reduce((tot, num) => tot + parseInt(num), 0);
    },
    image_list() {
      let array = [];
      for (let i = 0; i < this.item.img_list_count; i++) {
        array.push(this.item.img_list_src + i);
      }
      return array;
    },
    avatar_img() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        this.item.id +
        "?t=" +
        this.item.avatar_md5
      );
    },
  },
  methods: {
    open_dettaglio() {
      //this.$refs.dial_dett.open()
    },
    switch_pref() {
      this.item.preferito = !this.item.preferito;
      this.$store.dispatch("switch_pref", this.item);
    },
    prezzo_unitario(prezzo) {
      return (prezzo / this.item.unita).toFixed(2);
    },
    img_url(img){
      return process.env.VUE_APP_API_ROOT + "/prodotto/" + this.item.id +"/foto/" + img.id + "?t=" + img.md5
    },
    open_img(img) {
      this.image_open = this.img_url(img);
      this.dialog_enl = true;
    },
  },
};
