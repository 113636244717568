<template>
  <v-dialog v-model="dialog_color" max-width="400" persistent>
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-content>
          <v-list-item-title> Modifica </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="headline font-italic font-weight-light primary--text"
        >Modifica Colore</v-card-title
      >
      <v-form v-model="valid" ref="form">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                label="Nome"
                outlined
                v-model="nome"
                prepend-icon="mdi-palette"
                :rules="rules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-switch
              v-model="modifica_img"
              inset
              label="Modifica Immagine"
            ></v-switch>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="6" class="d-flex justify-center">
              <v-card
                class="portrait d-flex justify-center"
                :img="image_box"
                height="150"
                width="150"
                @click="open_file_window()"
              >
                <v-icon v-if="file == ''">{{
                  modifica_img ? "mdi-camera" : "mdi-camera-off"
                }}</v-icon>
                <input
                  type="file"
                  style="display: none"
                  @change="onFileChange($event)"
                  ref="input"
                />
              </v-card>
            </v-col>
          </v-row>
          <v-dialog width="200" v-model="load_bar">
            <v-progress-linear color="primary" :value="percentLoad" height="18">
              <span class="white--text">{{ percentLoad }}</span>
            </v-progress-linear>
          </v-dialog>
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn color="green" dark @click="invia" :loading="loading"
          >Fatto</v-btn
        >
        <v-btn text color="green" @click="dialog_color = false">Annulla</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import axios from "axios";
export default {
  components: {},
  props: {
    colore: Object,
    id_prodotto: String,
  },
  data() {
    return {
      dialog_color: false,
      nome: this.colore.nome,
      modifica_img: false,
      file: [],
      valid: true,
      exist: false,
      rules: [(v) => !!v || "Immettere Valore"],
      percentLoad: 0,
      load_bar: false,
      image_box: "",
      loading: false,
    };
  },
  methods: {
    open_file_window() {
      if (this.modifica_img) {
        this.$refs.input.click();
      }
    },
    onFileChange(e) {
      this.file = e.target.files[0];
      this.createImage(this.file);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.image_box = reader.result;
        },
        false
      );
      reader.readAsDataURL(file);
    },
    invia() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        let formData = {};
        if (this.modifica_img) {
          formData = new FormData();
          formData.append("file", this.file);
        }

        this.$store
          .dispatch("modifica_colore", {
            nome: this.nome,
            id_prodotto: this.id_prodotto,
            id_colore: this.colore.id,
            modifica_img: this.modifica_img,
            formData: formData,
          })
          .then(() => {
            this.loading = false;
            this.dialog_color = false;
          });
      }
    },
  },
};
</script>