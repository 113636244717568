import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      search_text: "",
      show_disp_only: true,
      loading: false,
      show_all: false,
    };
  },
  watch: {
    search_text: function () {
      if (this.search_text.length > 2) {
        this.show_disp_only = false;
        this.drop_all();
      }
    },
  },
  computed: {
    ...mapGetters({
      lista: "get_lista_prodotti",
    }),
    filtered() {
      if (this.search_text.length < 3 && !this.show_disp_only) {
        return this.lista.categorie;
      } else if (this.show_disp_only) {
        return this.lista.categorie
          .map((categoria) => {
            let filt_categ = categoria.sub_categorie
              .map((sub_categoria) => {
                let filt_prod = sub_categoria.prodotti.filter((prodotto) => {
                  return prodotto.disponibile;
                });
                return {
                  ...sub_categoria,
                  prodotti: filt_prod,
                };
              })
              .filter((sub_categ) => {
                return sub_categ.prodotti.length > 0;
              });
            return {
              ...categoria,
              sub_categorie: filt_categ,
            };
          })
          .filter((categ) => {
            return categ.sub_categorie.length > 0;
          });
      } else {
        let regex = new RegExp(this.search_text, "i");
        return this.lista.categorie.map((categoria) => {
          let filt_categ = categoria.sub_categorie
            .map((sub_categoria) => {
              let filt_prod = sub_categoria.prodotti.filter((prodotto) => {
                return regex.test(prodotto.nome);
              });
              return {
                ...sub_categoria,
                prodotti: filt_prod,
              };
            })
            .filter((sub_categ) => {
              return sub_categ.prodotti.length > 0;
            });
          return {
            ...categoria,
            sub_categorie: filt_categ,
          };
        });
        // .filter((categ) => {
        //   return categ.sub_categorie.length > 0;
        // });
      }
    },
  },
  methods: {
    drop_all() {
      this.$refs.categoria.forEach((categ) => {
        categ.drop_all();
      });
      this.show_all = false;
    },
    collapse_all() {
      this.$refs.categoria.forEach((categ) => {
        categ.collapse_all();
      });
      this.show_all = true;
    },
    bg_img(id) {
      return process.env.VUE_APP_API_ROOT + "/foto/categoria/" + id;
    },
    open_file_categ_window() {
      this.$refs.input_categ_img.click();
    },
    upload_categ_img: function (e) {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      let url =
        process.env.VUE_APP_API_ROOT +
        "/admin/categoria/img/" +
        this.categoria.id;
      let auth = this.$store.getters.get_credential;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          auth: auth,
          onUploadProgress: (progressEvent) => {
            this.percentLoadMain = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          this.categoria.img_md5 = res.data.md5;
          setTimeout(() => {
            this.percentLoadMain = 0;
          }, 1000);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$router.push("/login");
          }
        });
    },
  },
};
