<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on }">
      <v-btn color="green" outlined v-on="on">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Nuova Varietà
      </v-btn>
    </template>
    <v-card>
      <v-card-title> Nuova Varietà </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-row justify="center" class="mt-4">
            <v-col cols="12">
              <v-autocomplete
                outlined
                prepend-inner-icon="mdi-account"
                label="Fornitore"
                :items="fornitori"
                item-text="nome"
                item-value="id"
                v-model="id_fornitore"
              >
                <template v-slot:item="data">
                  <!-- <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template> -->
                  <template>
                    <v-list-item-avatar>
                      <v-img :src="avatar_fornitore(data.item.id)" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.nome"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                outlined
                prepend-inner-icon="mdi-palette"
                label="Colore"
                :items="prodotto.colori"
                item-text="nome"
                item-value="id"
                v-model="id_colore"
              >
                <template v-slot:item="data">
                  <!-- <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template> -->
                  <template>
                    <v-list-item-avatar>
                      <v-img :src="avatar_colore({id:data.item.id, md5: data.item.md5})" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.nome"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                prepend-inner-icon="mdi-file-outline"
                label="Nome"
                v-model="nome"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="dialog = false"> Annulla </v-btn>
        <v-btn outlined color="green" :loading="loading" @click="invia_varieta()">
          Conferma
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    prodotto: Object,
  },
  data() {
    return {
      dialog: false,
      valid: true,
      nome: null,
      id_colore: null,
      id_fornitore: null,
      loading:false
    };
  },
  computed: {
    fornitori() {
      return this.$store.getters.get_fornitori;
    },
  },
  methods: {
    avatar_fornitore(id_fornitore) {
      return (
        process.env.VUE_APP_API_ROOT + "/fornitore/" + id_fornitore + "/avatar"
      );
    },
    avatar_colore(colore) {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto.id +
        "/img_colore/" +
        colore.id + "?t=" + colore.md5
      );
    },
    invia_varieta() {
      this.loading = true;
      this.$store
        .dispatch("nuova_varieta", {
          nome: this.nome,
          id_colore: this.id_colore,
          id_fornitore: this.id_fornitore,
          id_prodotto: this.prodotto.id,
        })
        .then((res) => {
          this.$emit("nuova_varieta", res);
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>