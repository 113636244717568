<template>
  <v-dialog
    scrollable
    v-model="dialog_modifica"
    persistent
    width="900"
    @keypress.esc="close()"
  >
    <v-card>
      <v-img :src="main_img_url" height="120">
        <v-row justify="end" no-gutters
          ><v-btn icon class="ma-4" @click="open_file_main_window()"
            ><v-icon color="grey">mdi-camera</v-icon></v-btn
          >
        </v-row>
        <input
          type="file"
          style="display: none"
          @change="upload_main_img($event)"
          ref="input_main_img"
        />
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card-title class="">
        <v-list-item class="ml-n6 mt-n4 mb-n3">
          <v-list-item-avatar size="60">
            <!-- <v-icon color="primary" class="mr-3">mdi-spa</v-icon> -->
            <v-avatar size="60" @click="open_file_avatar_window()">
              <v-img contain :src="avatar_url" />
              <input
                type="file"
                style="display: none"
                @change="upload_avatar_img($event)"
                ref="input_avatar_img"
              />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 30px"
              class="font-italic font-weight-light primary--text"
            >
              {{ nome }}
            </v-list-item-title>
            <v-list-item-subtitle class="ml-1">
              {{ prodotto.nome_sc }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click="close()">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <v-divider />
      <v-card-text class="scroll">
        <v-row class="mt-0">
          <v-col cols="4">
            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-information </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> INFORMAZIONI </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-barcode </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>Cod. Contabilità </v-list-item-subtitle>
                <v-list-item-title>
                  <span style="font-size: 16px" class="">{{
                    prodotto.cod
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-diameter-variant </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle> Dimensione Vaso </v-list-item-subtitle>
                <v-list-item-title>
                  <span style="font-size: 16px" class="">{{
                    prodotto.diametro
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-google-circles-communities </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>Unità di Vendita </v-list-item-subtitle>
                <v-list-item-title>
                  <span style="font-size: 16px" class="">{{ unita }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-cart-outline </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>Unità per CC </v-list-item-subtitle>
                <v-list-item-title>
                  <span style="font-size: 16px" class="">{{
                    prodotto.qta_cc
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="8">
            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-palette </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> COLORI </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <draggable
              v-model="prodotto.colori"
              v-bind="dragOptions"
              @end="drag_start($event)"
            >
              <Colore
                v-for="colore in prodotto.colori"
                :prodotto="prodotto"
                :colore="colore"
                :key="colore.id"
              />
            </draggable>

            <NuovoColore :prodotto="prodotto" />

            <v-list-item class="ml-n6">
              <v-list-item-avatar>
                <v-icon> mdi-camera </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> GALLERIA </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col cols="3">
                <NuovaFoto :prodotto="prodotto.id" />
              </v-col>
              <v-col cols="3" v-for="foto in prodotto.img_list" :key="foto.id">
                <Foto :foto="foto" :prodotto="prodotto.id" />
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="3" v-for="(foto, index) in foto_array" :key="index">
                <FotoEnlarge :foto="foto" />
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              hide-details
              outlined
              label="Richiesta acqua"
              v-model="water_req"
              :items="water_list"
              item-value="value"
              item-text="text"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              hide-details
              outlined
              label="Esposizione"
              v-model="exposition"
              :items="exp_list"
              item-value="value"
              item-text="text"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              hide-details
              outlined
              label="Resistenza al gelo"
              v-model="frost_resist"
              :items="resist_list"
              item-value="value"
              item-text="text"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-currency-eur</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle> Listino 1 </v-list-item-subtitle>
                <v-list-item-title>
                  {{ prodotto.prezzo1 }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ prezzo_uni(prodotto.prezzo1) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="4">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-currency-eur</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle> Listino 2 </v-list-item-subtitle>
                <v-list-item-title>
                  {{ prodotto.prezzo2 }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ prezzo_uni(prodotto.prezzo2) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="4">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-currency-eur</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle> Listino 3 </v-list-item-subtitle>
                <v-list-item-title>
                  {{ prodotto.prezzo3 }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ prezzo_uni(prodotto.prezzo3) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row class="mt-2" no-gutters>
          <v-col>
            <v-text-field
              rows="1"
              label="Info"
              outlined
              hide-details
              counter="60"
              v-model="info"
            >
              <template v-slot:append>
                <v-progress-circular
                  indeterminate
                  color="green"
                  size="20"
                  width="2"
                  v-if="loading_info"
                ></v-progress-circular>
                <v-icon color="green" v-if="ok_info">mdi-check</v-icon>
                <v-icon color="red" v-if="fail_info">mdi-error</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <DialogVariet :prodotto="prodotto"></DialogVariet>
        <v-btn outlined class="mb-2" color="primary" @click="close()">
          <v-icon class="mr-2"> mdi-check </v-icon>
          Fatto
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import modifica_dialog_mixin from "../../Mixin/modifica_dialog_mixin";
import Colore from "./colore_element.vue";
import NuovoColore from "./nuovo_colore_form.vue";
import NuovaFoto from "./nuova_foto.vue";
import DialogVariet from "../varietà_dialog/varietà_dialog_main.vue";
import Foto from "./foto_portrait.vue";
import draggable from "vuedraggable";
export default {
  components: {
    Colore,
    NuovoColore,
    NuovaFoto,
    DialogVariet,
    Foto,
    draggable,
  },
  mixins: [modifica_dialog_mixin],
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>