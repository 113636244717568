<template>
  <v-dialog v-model="dialog_color" max-width="350" persistent>
    <template v-slot:activator="{ on }">
      <v-avatar
        style="cursor: pointer"
        color="grey lighten-3"
        size="70"
        class="mr-2 mt-2"
        v-on="on"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-avatar>
    </template>
    <v-card>
      <v-card-title class="headline font-italic font-weight-light primary--text"
        >Nuovo Colore</v-card-title
      >
      <v-form v-model="valid" ref="form">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                label="Nome"
                outlined
                v-model="nome"
                prepend-icon="mdi-palette"
                :rules="rules"
                @keypress.enter="invia()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="6" class="d-flex justify-center">
              <v-card
                class="portrait d-flex justify-center"
                :img="image_box"
                height="150"
                width="150"
                @click="open_file_window()"
              >
                <v-icon v-if="file == ''">mdi-camera</v-icon>
                <input
                  type="file"
                  style="display: none"
                  @change="onFileChange($event)"
                  ref="input"
                />
              </v-card>
            </v-col>
          </v-row>
          <v-dialog width="200" v-model="load_bar">
            <v-progress-linear color="primary" :value="percentLoad" height="18">
              <span class="white--text">{{ percentLoad }}</span>
            </v-progress-linear>
          </v-dialog>
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />

        <v-btn text color="grey" @click="close()">Annulla</v-btn>
        <v-btn color="green" outlined @click="invia" :loading="loading" :disabled="file == null || nome == ''"
          ><v-icon class="mr-2">mdi-check</v-icon> Fatto</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import axios from "axios";
export default {
  components: {},
  props: {
    prodotto: Object,
  },
  data() {
    return {
      dialog_color: false,
      nome: "",
      file: null,
      valid: true,
      rules: [(v) => !!v || "Immettere Valore"],
      percentLoad: 0,
      load_bar: false,
      image_box: "",
      loading: false,
    };
  },
  methods: {
    open_file_window() {
      this.$refs.input.click();
    },
    onFileChange(e) {
      this.file = e.target.files[0];
      this.createImage(this.file);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.image_box = reader.result;
        },
        false
      );
      reader.readAsDataURL(file);
    },
    invia() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        if (!this.file) {
          this.loading = false;
          return;
        }
        let formData = new FormData();
        formData.append("file", this.file);
        this.$store
          .dispatch("nuovo_colore", {
            nome: this.nome,
            id_prodotto: this.prodotto.id,
            formData: formData,
          })
          .then(() => {
            this.loading = false;
            this.close()
          });
      }
    },
    close(){
      this.file = null
      this.image_box = ""
      this.nome = ""
      this.dialog_color = false
    }
  },
};
</script>