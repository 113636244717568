<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-dialog
          :close-on-content-click="false"
          :nudge-width="250"
          nudge-right="5"
          offset-x
          top
          width="400"
          v-model="menu"
        >
          <template v-slot:activator="{ on: menu }">
            <v-badge
              color="primary"
              :value="colore.disponibile"
              icon="mdi-check"
              overlap
              bordered
              offset-x="20"
              offset-y="20"
            >
              <v-avatar
                v-on="{ ...menu, ...tooltip }"
                class="mr-2"
                size="70"
                style="cursor: pointer"
              >
                <v-img :src="img_url">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-icon>mdi-image-off</v-icon>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </v-badge>
          </template>
          <v-card>
            <v-card-title class="green--text font-italic">
              {{ colore.nome }}
              <v-spacer />
              <v-btn icon @click="menu = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-title>
            <v-card-text class="mb-n6">
              <v-switch
                inset
                :loading="color_loading"
                label=" Disbonibile"
                class="pa-2"
                v-model="disponibile"
              />
            </v-card-text>

            <v-list>
              <v-divider />
              <ModificaColore :colore="colore" :id_prodotto="prodotto.id" />

              <v-dialog v-model="conferma_elimina" width="400">
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>Elimina</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <v-card>
                  <v-card-title class="red--text">
                    Elimina Colore
                  </v-card-title>
                  <v-card-text> Sei sicuro? </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn outlined color="red" @click="delete_color()">
                      <v-icon class="mr-2">mdi-delete</v-icon>
                      Elimina
                    </v-btn>
                    <v-btn text color="grey" @click="conferma_elimina = false"
                      >Annulla</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list>
          </v-card>
        </v-dialog>
      </template>
      <span>{{ colore.nome }}</span>
    </v-tooltip>
  </span>
</template>

<script>
import ModificaColore from "./modifica_colore.vue";
export default {
  data() {
    return {
      menu: false,
      color_loading: false,
      random_date: 0,
      dialog_edit_color: false,
      disponibile: this.colore.disponibile,
      conferma_elimina: false,
    };
  },
  props: {
    colore: Object,
    prodotto: Object,
  },
  components: {
    ModificaColore,
  },
  watch: {
    menu: function () {},
    disponibile: function () {
      this.color_loading = true;
      this.$store
        .dispatch("cambia_colore_disponibile", {
          id_prodotto: this.prodotto.id,
          id_colore: this.colore.id,
          disponibile: this.disponibile,
        })
        .then(() => {
          this.color_loading = false;
          if (!this.prodotto.colori.some((col) => col.disponibile == true)) {
            this.$store.dispatch("cambia_disponibile", {
              id: this.prodotto.id,
              stato: false,
            });
          }
        });
    },
  },
  computed: {
    avatar_url: function () {
      return this.colore.url + "?t=" + this.random_date;
      //return this.$store.getters.getColorLink + this.prodotto.color_cod + "/" + this.colore.nome + ".jpg?t=" + this.random_date
    },
    img_url() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto.id +
        "/img_colore/" +
        this.colore.id +
        "?t=" +
        this.colore.md5
      );
    },
  },
  methods: {
    set_color() {
      this.color_loading = true;
      this.$store
        .dispatch("toggle_product_color", {
          item: this.prodotto,
          colore: this.colore,
        })
        .then(() => {
          this.color_loading = false;
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$router.push("/login");
          }
        });
    },
    delete_color() {
      if (confirm) {
        this.$store
          .dispatch("elimina_colore", {
            id_prodotto: this.prodotto.id,
            id_colore: this.colore.id,
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.$router.push("/login");
            }
          });
      }
    },
    handle_edit_colror() {
      this.random_date = Date.now();
      this.dialog_edit_color = false;
    },
  },
  mounted() {
    this.random_date = Date.now();
  },
};
</script>