<template>
  <div>
    <v-row align="center" class="mb-2">
      <v-toolbar tile elevation="0" :src="categ_img_url" class="mt-2">
        <v-btn icon @click="collapse_categ = !collapse_categ">
          <v-icon color="white">
            {{ collapse_categ ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
        <v-btn icon @click="collapse_sub = !collapse_sub">
          <v-icon color="white">
            {{ collapse_sub ? "mdi-collapse-all" : "mdi-expand-all" }}
          </v-icon>
        </v-btn>
        <v-toolbar-title class="white--text text-uppercase">
          {{ categoria.nome }}
        </v-toolbar-title>
        <v-switch class="ml-8" v-model="categ_enabled" hide-details inset></v-switch>
        <v-spacer />
        
        <v-btn icon class="ma-4" @click="open_file_categ_window()">
          <v-icon color="grey">mdi-camera</v-icon>
        </v-btn>
        <input
          type="file"
          style="display: none"
          @change="upload_categ_img($event)"
          ref="input_categ_img"
        />
      </v-toolbar>
    </v-row>
    <template v-if="collapse_categ">
      <SubCategList
        v-for="(sub_categ, index) in categoria.sub_categorie"
        :key="sub_categ.nome + index"
        :sub_categ="sub_categ"
        :show="collapse_sub"
        :not_first="index != 0"
      />
    </template>
  </div>
</template>

<script>
// import prodotti_mixin from "../Mixin/prodotti_mixin";
import categoria_mixin from "../Mixin/categoria_mixin"
import SubCategList from "./sub_categoria_desktop";
export default {
  components: {
    SubCategList,
  },
  props: {
    categoria: Object,
  },
  mixins: [ categoria_mixin],
  methods: {
    clear() {
      this.search_string = "";
    },
  },
};
</script>