<template>
  <v-card
    class="portrait"
    :img="img_url"
    height="60"
    width="100"
    v-bind="attrs"
    v-on="on"
    @contextmenu="show"
    @click="enlarge"
  >
    <v-dialog v-model="dialog_img" width="1200">
      <v-card @click="dialog_img = false">
          <v-img :src="img_url"/>
      </v-card>
    </v-dialog>
    <v-menu
      v-model="del_menu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item icon @click="remove_img()">
          <v-list-item-title>Elimina</v-list-item-title>
          <v-list-item-action>
              <v-icon color="red">mdi-delete</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: {
    foto: Object,
    prodotto: String,
  },
  data() {
    return {
      dialog_img: false,
      del_menu: false,
      x: 0,
      y: 0,
    };
  },
  computed: {
    img_url() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto +
        "/foto/" +
        this.foto.id +
        "?t=" +
        this.foto.md5
      );
    },
  },
  methods: {
    enlarge() {
        this.dialog_img = true
    },
    show(e) {
      e.preventDefault();
      this.del_menu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.del_menu = true;
      });
    },
    remove_img() {
      var url =
        process.env.VUE_APP_API_ROOT +
        "/admin/prodotto/" +
        this.prodotto +
        "/foto/" +
        this.foto.id;
      let auth = this.$store.getters.get_credential;
      axios
        .delete(url, {
          auth: auth,
        })
        .then(() => {
          this.$store.commit("del_foto_prodotto", {
            id_prodotto: this.prodotto,
            id_foto: this.foto.id,
          });
        })
        .catch((err) => {
          if (err.responce.status == 400) {
            this.$router.push("/login");
          }
        });
    },
  },
};
</script>