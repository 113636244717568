<template>
  <div>
    <v-text-field
      v-model="search_text"
      hide-details
      outlined
      label="Cerca ..."
    ></v-text-field>
    <v-switch
      inset
      label="Mostra solo disponibili"
      v-model="show_disp_only"
    ></v-switch>
    <v-list>
      <template v-for="(categoria, index) in filtered">
        <v-list-item :key="index" @click="categoria.show = !categoria.show">
          <v-list-item-avatar>
            <v-icon>{{
              categoria.show ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-overline green--text">
              {{ categoria.nome }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="categoria.show">
          <SubcategMobile
            v-for="sub_categ in categoria.sub_categorie"
            :key="sub_categ.nome + index"
            :sub_categ="sub_categ"
            :show="show_all"
          />
        </template>
      </template>
    </v-list>
  </div>
</template>

<script>
import prodotti_mixin from "../Mixin/prodotti_mixin";
import SubcategMobile from "./sub_categoria_mobile.vue";
export default {
  components: {
    SubcategMobile,
  },
  mixins: [prodotti_mixin],
};
</script>