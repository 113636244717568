<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-img :src="avatar_img"> </v-img>
    </v-list-item-avatar>
    <v-list-item-content @click="$refs.dialog_prodotto.open()">
      <v-list-item-title> {{ nome }} Ø {{ item.diametro }} </v-list-item-title>
      <v-list-item-subtitle>
        {{ tipo_vaso }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <span style="font-size: 12px"
          >€ {{ item.prezzo1 }} - € {{ item.prezzo2 }} - € {{ item.prezzo3 }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <span style="font-size: 12px"
          >€ {{ prezzo_unitario(item.prezzo1) }} - € {{ prezzo_unitario(item.prezzo2) }} - € {{ prezzo_unitario(item.prezzo3) }}
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-switch
        inset
        v-model="disponibile"
        :loading="loading_change"
        :disabled="loading_change"
      ></v-switch>
    </v-list-item-action>
    <DialogProdotto :prodotto="item" ref="dialog_prodotto" />
  </v-list-item>
</template>

<script>
import prodotto_element_mixin from "../Mixin/elemento_prodotto_mixin";
import DialogProdotto from "./modifica_dialog_mobile/prodotto_dettaglio_mobile.vue";
export default {
  components: { DialogProdotto },
  mixins: [prodotto_element_mixin],
};
</script>