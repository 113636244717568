import axios from "axios";
import _ from "lodash";
export default {
  props: {
    prodotto: Object,
  },
  created() {
    this.debounceInfo = _.debounce(this.set_info, 2000);
  },
  data() {
    return {
      dialog_modifica: false,
      percentLoadMain: 0,
      loading_info: false,
      info: this.prodotto.info,
      ok_info: false,
      fail_info: false,
      water_req: this.prodotto.water_request,
      exposition: this.prodotto.exposition,
      frost_resist: this.prodotto.frost_resist,
      water_list: [
        { text: "Bassa", value: 1 },
        { text: "Media", value: 2 },
        { text: "Alta", value: 3 },
      ],
      exp_list: [
        { text: "Pieno Sole", value: 1 },
        { text: "Mezza Ombra", value: 2 },
        { text: "Ombra", value: 3 },
      ],
      resist_list: [
        { text: "Bassa", value: 1 },
        { text: "Alta", value: 2 },
      ],
    };
  },
  computed: {
    main_img_url() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/main_img/" +
        this.prodotto.id +
        "?t=" +
        this.prodotto.img_md5
      );
    },
    avatar_url() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        this.prodotto.id +
        "?t=" +
        this.prodotto.avatar_md5
      );
    },
    nome() {
      if (this.prodotto.nome.includes("Vaso")) {
        return this.prodotto.nome.split("Vaso")[0];
      } else {
        return this.prodotto.nome.split("Basket")[0];
      }
    },
    unita() {
      if (this.prodotto.unita == "1") {
        return "Vaso Singolo";
      } else {
        return "Cassa da " + this.prodotto.unita;
      }
    },

    prezzo_unitario() {
      return (this.item.prezzo / this.item.unita).toFixed(2);
    },
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#dettaglio-prodotto" && this.dialog_modifica) {
        this.dialog_modifica = false;
      }
    },
    // dialog_modifica: function () {
    //   if (this.dialog_modifica) {
    //     this.$router.push("#dettaglio-prodotto");
    //   }
    // },
    info: function () {
      this.debounceInfo();
    },
    water_req: function () {
      this.$store.dispatch("modifica_water_req", {
        id_prodotto: this.prodotto.id,
        value: this.water_req,
      });
    },
    exposition: function () {
      this.$store.dispatch("modifica_exposition", {
        id_prodotto: this.prodotto.id,
        value: this.exposition,
      });
    },
    frost_resist: function () {
      this.$store.dispatch("modifica_frost_resist", {
        id_prodotto: this.prodotto.id,
        value: this.frost_resist,
      });
    },
  },
  methods: {
    prezzo_uni(prezzo) {
      return (prezzo / this.prodotto.unita).toFixed(2);
    },
    open() {
      this.dialog_modifica = true;
      this.$router.push("#dettaglio-prodotto");
    },
    close() {
      this.dialog_modifica = false;
      this.$router.back();
    },
    open_file_main_window() {
      this.$refs.input_main_img.click();
    }, 
    open_file_avatar_window() {
      this.$refs.input_avatar_img.click();
    },
    upload_main_img: function (e) {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      let url =
        process.env.VUE_APP_API_ROOT +
        "/admin/prodotto/img/" +
        this.prodotto.id;
      let auth = this.$store.getters.get_credential;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          auth: auth,
          onUploadProgress: (progressEvent) => {
            this.percentLoadMain = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          this.prodotto.img_md5 = res.data.md5;
          setTimeout(() => {
            this.percentLoadMain = 0;
          }, 1000);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$router.push("/login");
          }
        });
    },
    upload_avatar_img: function (e) {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      let url =
        process.env.VUE_APP_API_ROOT +
        "/admin/prodotto/avatar/" +
        this.prodotto.id;
      let auth = this.$store.getters.get_credential;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          auth: auth,
          onUploadProgress: (progressEvent) => {
            this.percentLoadMain = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          this.prodotto.avatar_md5 = res.data.md5;
          setTimeout(() => {
            this.percentLoadMain = 0;
          }, 1000);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$router.push("/login");
          }
        });
    },
    drag_start(e) {
      let url =
        process.env.VUE_APP_API_ROOT +
        "/admin/prodotto/colore/" +
        this.prodotto.id;
      let auth = this.$store.getters.get_credential;
      let json = {
        new: e.newIndex,
        old: e.oldIndex,
      };
      axios.put(url, json, { auth: auth });
    },
    set_info() {
      this.loading_info = true;
      this.$store
        .dispatch("aggiungi_commento", {
          id_prodotto: this.prodotto.id,
          info: this.info,
        })
        .then(() => {
          this.loading_info = false;
          this.ok_info = true;
          setTimeout(() => {
            this.ok_info = false;
          }, 1000);
        })
        .catch(() => {
          this.loading_info = false;
          this.fail_info = true;
          setTimeout(() => {
            this.fail_info = false;
          }, 1000);
        });
    },
  },
};
