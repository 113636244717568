import axios from "axios";

export default {
  data() {
    return {
      collapse_sub: true,
      collapse_categ: true,
      dispo_loading: false,
      categ_enabled: this.categoria.disponibile,
      md5: this.categoria.md5,
    };
  },
  watch: {
    categ_enabled: function () {
      this.dispo_loading = true;
      this.$store
        .dispatch("change_categ_dispo", {
          categ_id: this.categoria.id,
          value: this.categ_enabled,
        })
        .finally(() => {
          this.dispo_loading = false;
        });
    },
  },
  computed: {
    categ_img_url() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/foto/categoria/" +
        this.categoria.id +
        "?t=" +
        this.md5
      );
    },
  },
  methods: {
    drop_all() {
      this.collapse_sub = true;
      this.collapse_categ = true;
    },
    collapse_all() {
      this.collapse_sub = false;
      this.collapse_categ = false;
    },
    open_file_categ_window() {
      this.$refs.input_categ_img.click();
    },
    upload_categ_img: function (e) {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      let url =
        process.env.VUE_APP_API_ROOT +
        "/admin/categoria/img/" +
        this.categoria.id;
      let auth = this.$store.getters.get_credential;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          auth: auth,
          onUploadProgress: (progressEvent) => {
            this.percentLoadMain = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          this.md5 = res.data.md5;
          setTimeout(() => {
            this.percentLoadMain = 0;
          }, 1000);
        });
    },
  },
};
