<template>
  <div>
    <v-card
      class="portrait d-flex justify-center"
      color="grey lighten-3"
      height="60"
      width="100"
      @click="open_file_window()"
    >
      <input
        type="file"
        style="display: none"
        ref="input"
        @change="onFileChange($event)"
      />
      <v-icon v-if="!new_img_loading" class="align-self-center" color="white"
        >mdi-plus</v-icon
      >
      <v-progress-linear
        v-if="new_img_loading"
        class="align-self-center mx-4"
        :value="percentLoadNewImg"
      ></v-progress-linear>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    prodotto: String,
  },
  data() {
    return {
      new_img_loading: false,
      new_img_file: [],
      percentLoadNewImg: 0,
    };
  },
  methods: {
    open_file_window() {
      this.$refs.input.click();
    },
    onFileChange: function (e) {
      if (e.target.files[0] != null) {
        this.upload_new_img(e.target.files[0]);
      }
    },
    upload_new_img(file) {
      this.new_img_loading = true;
      var url =
        process.env.VUE_APP_API_ROOT +
        "/admin/prodotto/" +
        this.prodotto +
        "/foto";
      let auth = this.$store.getters.get_credential;
      let formData = new FormData();
      formData.append("file", file);
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          auth: auth,
          onUploadProgress: (progressEvent) => {
            this.percentLoadNewImg = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((res) => {
          this.$store.commit("add_nuova_foto", {
            id_prodotto: this.prodotto,
            foto: res.data,
          });
          setTimeout(() => {
            this.new_img_loading = false;
            this.percentLoadNewImg = 0;
          }, 600);
        })
        .catch((err) => {
          if (err.responce.status == 400) {
            this.$router.push("/login");
          }
        })
        .finally(() => {
          this.new_img_file = [];
        });
    },
  },
};
</script>