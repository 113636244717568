<template>
  <v-dialog v-model="dialog" width="800" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn
        :outlined="!icon"
        :class="!icon ? 'mb-2' : ''"
        color="purple"
        v-on="on"
        :icon="icon"
      >
        <v-icon :class="!icon ? 'mr-2' : ''"> mdi-barcode </v-icon>
        {{ icon ? "" : "Varietà" }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Varietà
        <v-spacer />
        <NuovaVarieta :prodotto="prodotto" @nuova_varieta="add_var" />
      </v-card-title>
      <v-card-text>
        <template v-if="loading">
          <v-row justify="center" align="center" no-gutters>
            <v-progress-circular
              width="2"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </template>
        <template v-else>
          <template v-if="!varieta.length">
            <v-row justify="center" align="center" no-gutters>
              Nessuna Varietà Registrata</v-row
            >
          </template>
          <template v-else>
            <v-row no-gutters class="my-2">
              <v-col cols="1" />
              <v-col cols="4"> Nome </v-col>
              <v-col cols="3"> Colore </v-col>
              <v-col cols="4"> Fornitore </v-col>
            </v-row>
            <ListaFornitore
              v-for="lista in liste"
              :key="lista.id_fornitore"
              :fornitore="lista.id_fornitore"
              :lista="lista"
              :prodotto="prodotto"
              @eliminata="eliminata"
            />
          </template>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="dialog = false">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import NuovaVarieta from "./varieta_nuovo.vue";
import ListaFornitore from "./varietà_fornitore_lista.vue";
export default {
  components: {
    NuovaVarieta,
    ListaFornitore,
  },
  props: {
    prodotto: Object,
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      varieta: [],
      loading: false,
    };
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        this.loading = true;
        this.$store
          .dispatch("fetch_varieta", this.prodotto.id)
          .then((res) => (this.varieta = res))
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  computed: {
    liste() {
      return Object.values(_.groupBy(this.varieta, "id_fornitore"));
    },
  },
  methods: {
    add_var: function (variet) {
      this.varieta.push(variet);
    },
    eliminata: function (id_varieta) {
      let index = this.varieta.findIndex((variet) => variet.id == id_varieta);
      this.varieta.splice(index, 1);
    },
  },
};
</script>