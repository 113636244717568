<template>
  <ProdottiDesktop v-if="!$vuetify.breakpoint.mobile" />
  <ProdottiMobile v-else/>
</template>

<script>
import ProdottiDesktop from "@/components/Prodotti/desktop/prodotti_desktop.vue"
import ProdottiMobile from "@/components/Prodotti/mobile/prodotti_mobile.vue"
export default {
  components: {
    ProdottiDesktop,
    ProdottiMobile
  },
};
</script>