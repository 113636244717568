<template>
  <v-card :loading="loading">
    <v-card-title class="grey--text text-subtitle-1"> Prodotti </v-card-title>
    <v-card-text>
      <v-row class="mb-4" align="center">
        <v-col cols="6">
          <v-text-field
            v-model="search_text"
            hide-details
            outlined
            label="Cerca ..."
            append-icon="mdi-delete"
            @click:append="search_text = ''"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-switch
            inset
            label="Mostra solo disponibili"
            v-model="show_disp_only"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row justify="start" no-gutters class="my-3" align="center">
        <v-col cols="1">
          <v-btn icon @click="drop_all()" v-if="show_all">
            <v-icon>mdi-expand-all</v-icon>
          </v-btn>
          <v-btn icon @click="collapse_all()" v-else>
            <v-icon>mdi-collapse-all</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="3"> Nome </v-col>
        <v-col cols="1"> Diam. </v-col>
        <v-col cols="3"> Prezzi </v-col>
        <v-col cols="1"> </v-col>
        <v-col cols="3"> </v-col>
      </v-row>
      <template v-if="filtered.length > 0">
        <template v-for="categoria in filtered">
          <Categoria
            :categoria="categoria"
            :key="categoria.id"
            ref="categoria"
          />
        </template>
      </template>
      <template v-else>
        <v-row justify="center" class="my-6"> Nessun Prodotto </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import prodotti_mixin from "../Mixin/prodotti_mixin";
import Categoria from "./categoria_desktop.vue";
export default {
  components: {
    Categoria,
  },
  mixins: [prodotti_mixin],
  data() {
    return {};
  },
  methods: {
    clear() {
      this.search_string = "";
    },
  },
};
</script>