<template>
  <div>
    <v-divider />
    <v-row align="center" no-gutters class="py-2">
      <v-col cols="1" class="d-flex justify-center">
        <v-avatar size="30">
          <v-img :src="avatar_src" />
        </v-avatar>
      </v-col>
      <v-col cols="4" class="text-h6 green--text font-weight-light">
        {{ fornitore.nome }}
      </v-col>
      <v-col cols="4"> </v-col>
      <v-col cols="1"> </v-col>
      <v-col cols="2">
        <v-btn icon color="purple" @click="open_stampa = true"
          ><v-icon>mdi-printer</v-icon></v-btn
        >
        <v-btn icon @click="show_all = !show_all">
          <v-icon color="grey">{{
            show_all ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
        <DialogStampa :open="open_stampa" :prodotto_from="prodotto.id" :fornitore_from="fornitore.id" @close="open_stampa = false" />
      </v-col>
    </v-row>

    <template v-if="show_all">
      <v-divider />
      <!-- <v-row
        v-for="varieta in lista"
        :key="varieta.id"
        no-gutters
        align="center"
        class="my-2"
      >
        <v-col cols="1" class="d-flex justify-center">
          <v-avatar size="20">
            <v-img :src="avatar_colore(varieta.id_colore)" />
          </v-avatar>
        </v-col>
        <v-col cols="4">
          {{ varieta.nome }}
        </v-col>
        <v-col cols="3">
          {{ colore(varieta.id_colore).nome }}
        </v-col>
        <v-col cols="3">
          {{ fornitore.nome }}
        </v-col>
      </v-row> -->
      <VarietaElement
        v-for="varieta in lista"
        :key="varieta.id"
        :varieta="varieta"
        :prodotto="prodotto"
        @eliminata="eliminata"
      />
    </template>
  </div>
</template>

<script>
import VarietaElement from "./varieta_element.vue";
import DialogStampa from "../../../Lotti/desktop/stampa_varieta.vue";
export default {
  components: {
    VarietaElement,
    DialogStampa,
  },
  props: {
    prodotto: Object,
    lista: Array,
  },
  data() {
    return {
      show_all: true,
      open_stampa: false,
    };
  },
  computed: {
    fornitore() {
      return this.$store.getters.get_fornitore_by_id(
        this.lista[0].id_fornitore
      );
    },
    avatar_src() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/fornitore/" +
        this.lista[0].id_fornitore +
        "/avatar"
      );
    },
  },
  methods: {
    colore(id_colore) {
      return this.prodotto.colori.find((col) => col.id == id_colore);
    },
    avatar_colore(id_colore) {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto.id +
        "/img_colore/" +
        id_colore
      );
    },
    eliminata: function (id_varieta) {
      this.$emit("eliminata", id_varieta);
    },
  },
};
</script>